<template>
  <transition
    :name="`drawer-${position}`"
    v-bind="$attrs">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "DrawerTransition",
  props: {
    position: {
      type: String,
      default: "right"
    }
  }
};
</script>

<style>
.drawer-left-leave-active,
.drawer-left-enter-active,
.drawer-right-leave-active,
.drawer-right-enter-active,
.drawer-bottom-leave-active,
.drawer-bottom-enter-active {
  transition: transform 0.3s ease-in-out;
}

.drawer-left-enter-from,
.drawer-left-leave-to {
  transform: translate(-100%, 0);
}

.drawer-right-enter-from,
.drawer-right-leave-to {
  transform: translate(100%, 0);
}

.drawer-bottom-enter-from,
.drawer-bottom-leave-to {
  transform: translate(0, 100%);
}
</style>
